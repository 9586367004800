import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
  selectedVersesRef: string;
  selectedVersesText: string;
  shareLink: string;
  onClose?: () => void;
}

export default function ShareButtons({ selectedVersesRef, selectedVersesText, shareLink, onClose }: Props) {

  return (
    <div className="App-share">
      <div className="App-share-buttons">
        <CopyToClipboard text={selectedVersesText}>
          <button><i className="bx bx-copy"></i></button>
        </CopyToClipboard>
        <CopyToClipboard text={shareLink}>
          <button>
            <i className="bx bx-link"></i>
          </button>
        </CopyToClipboard>
        <button>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-facebook"></i>
          </a>
        </button>
        <button>
          <a
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(selectedVersesText)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/x-logo.svg" />
          </a>
        </button>
        <button>
          <a
            href={`https://wa.me/?text=${encodeURIComponent(selectedVersesText)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-whatsapp"></i>
          </a>
        </button>
        <button><a href={`mailto:?subject=${selectedVersesRef}&body=${encodeURIComponent(selectedVersesText)}`}><i className="bx bx-envelope"></i></a></button>
        {onClose && <button onClick={onClose}><i className="bx bx-x"></i></button>}
      </div>
    </div>
  );
}