import './App.css';
import { Route, Routes } from 'react-router-dom';
import Books from './pages/books';
import Layout from './layout';
import NoPage from './pages/noPage';
import Search from './pages/search';
import Book from './pages/book';
import Chapter from './pages/chapter';
import About from './pages/about';
import Privacy from './pages/privacy';
import BibleLayout from './bibleLayout';
import Home from './pages/home';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path=":translation" element={<BibleLayout />}>
          <Route index element={<Books />} />
          <Route path="about" element={<About />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="search" element={<Search />} />
          <Route path="search/:terms" element={<Search />} />
          <Route path=":book" element={<Book />} />
          <Route path=":book/:chapter" element={<Chapter />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
