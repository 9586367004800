import ReactAudioPlayer from "react-audio-player"

interface Props {
  src: string;
  autoPlay: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
}

export default function AudioPlayer({ src, autoPlay, onPlay, onPause, onEnded }: Props) {
  return (
    <ReactAudioPlayer
      src={src}
      controls
      autoPlay={autoPlay}
      className="App-audio-player"
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onEnded}
    />
  )
}
