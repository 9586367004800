import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  translation: string;
  versionName: string;
  versionAbbreviation: string;
  publishDate: string;
  license: string;
  locale: string;
}

const Footer: React.FC<Props> = ({ translation, versionName, versionAbbreviation, publishDate, license, locale }) => {
  const formattedDate = publishDate && new Date(publishDate).toLocaleDateString(locale);

  return (
    <footer className="App-footer">
      <div>
        <span>{versionName}</span>
        <span> ({versionAbbreviation}),</span>
        <span> {formattedDate},</span>
        <span> {license}</span>
      </div>
      <div className="App-footer-links">
        <Link to={`/${translation}/about`} className="App-link">About</Link> | <Link to={`/${translation}/privacy`} className="App-link">Privacy</Link>
      </div>
    </footer>
  );
};

export default Footer;
