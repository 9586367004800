const replacements = [
  {
    regex: new RegExp("<TS[0-9]?>.*<Ts>[ ]?", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<CL>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<CM>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<CI>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<PI[0-9]?>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<FR>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<Fr>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<FO>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<Fo>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<FU>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<Fu>", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("<N[0-9]?>.*<n>[ ]?", "g"),
    replacement: "",
  },
  {
    regex: new RegExp("[{].*[}][ ]?", "g"),
    replacement: "",
  },
];

export default function processBibleTags(text: string): string {
  let result = text;
  for (const replacement of replacements) {
    result = result.replace(replacement.regex, replacement.replacement);
  }
  result = result.replaceAll("  ", " ");
  result = result.replaceAll(" .", ".");
  result = result.replaceAll(" !", "!");
  result = result.replaceAll(" ?", "?");
  return result;
}

export function removeAllTags(text: string): string {
  let result = processBibleTags(text);
  result = result.replace(/<[^>]*>/g, "");
  result = result.replaceAll("*", "");
  result = result.replaceAll("°", "");
  return result;
}
