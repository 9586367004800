import ContactEmail from "../components/email";

export default function Privacy() {

  return (
    <section>
      <div className="App-content">
        <h1>Privacy Policy</h1>
        <p>At OpenVersion.net, we are committed to protecting your privacy and maintaining your trust in our services. This Privacy Policy explains how we collect, use, and share personal information from visitors to our website.</p>

        <h2>Information Collection and Use</h2>

        <p>We collect the following types of information from our website:</p>
        <ul>
          <li>IP addresses</li>
          <li>Browser type and operating system</li>
          <li>Date and time of visit</li>
          <li>Web pages viewed</li>
        </ul>

        <p>
          This information is collected for statistical and analytical purposes, such as evaluating trends, improving site performance, and creating new features. We do not collect personal
          information, such as names or email addresses, from our website visitors.
        </p>

        <h2>Sharing of Information</h2>

        <p>We may share the following types of information with third-party partners:</p>

        <ul><li>Aggregated statistical data (e.g., number of visitors from a particular country)</li></ul>

        <p>We may also share non-personal information with third parties for marketing or promotional purposes. However, we will not share personal information with third parties without your
          explicit consent.</p>

        <h2>Security Measures</h2>

        <p>We take appropriate measures to protect against unauthorized access, disclosure, and use of the information collected through our website. These measures include:</p>

        <ul>
          <li>Encryption of sensitive data</li>
          <li>Secure servers for storing data</li>
          <li>Regular security audits and testing</li>
        </ul>

        <h2>Legal Basis</h2>

        <p>We collect and use personal information based on legitimate interests as described in this Privacy Policy. We also comply with applicable laws and regulations regarding data
          protection.</p>

        <h2>Updates</h2>

        <p>This Privacy Policy may change from time to time, so please review it periodically for any updates. Your continued use of our website constitutes your acceptance of the current Privacy
          Policy.</p>

        <h2>Contact Information</h2>

        <p>If you have questions or concerns about this Privacy Policy, please contact us at <ContactEmail />.</p>

        <p>By using our website, you acknowledge that you have read and understood this Privacy Policy and agree to be bound by its terms.</p>
      </div>
    </section>
  );
}
