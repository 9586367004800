export default function ContactEmail() {
  return (
    <span>
      mail
      <span>[at]</span>
      <span>Majority<span></span>Version</span>
      <span>[dot]</span>
      com
    </span>
  );
}
