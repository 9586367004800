import { useContext, useEffect, useState } from 'react';
import Navigation from '../components/navigation';
import { Bible } from '../types/bibleTypes';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/loader';
import NoPage from './noPage';
import AudioPlayer from '../components/audioPlayer';
import ShareButtons from '../components/shareButtons';
import BibleContext from '../contexts/bibleContext';
import VerseText from '../components/verseText';
import { removeAllTags } from '../modules/bible-tag-processor';

export default function Chapter() {
  const { translation, bible } = useContext(BibleContext);
  const { book, chapter } = useParams<{ book: string, chapter: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const autoPlay = queryParams.get('autoplay') === 'true';

  const [selectedVerseNumbers, setSelectedVerseNumbers] = useState<number[]>([]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [stickyPlayer, setStickyPlayer] = useState(false);

  useEffect(() => {
    function updateOnlineStatus() {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    setStickyPlayer(false);
    const verseNumbersCsv = window.location.hash.substring(1); // Remove the '#' from the hash
    if (!verseNumbersCsv) {
      if (selectedVerseNumbers) {
        setSelectedVerseNumbers([]);
      }
      const header = document.getElementById("header");
      if (header) {
        header.scrollIntoView();
      }
      return;
    }
    const verseNumbers = verseNumbersCsv.split(',').map(v => parseInt(v));
    setSelectedVerseNumbers(verseNumbers);
    const verseElement = document.getElementById(`verse-${verseNumbers[0]}`);
    if (verseElement) {
      verseElement.scrollIntoView();
    }
  }, [bible, book, chapter]);

  const navigate = useNavigate();

  if (!bible) {
    return <Loader />;
  }

  const bookNumber = parseInt(book || '1');
  const chapterNumber = parseInt(chapter || '1');
  const bookData = bible.parts.flatMap(part => part.books)
    .find(book => book.i === bookNumber);
  const chapterData = bookData?.c.find(chapter => chapter.i === chapterNumber);
  const verses = chapterData?.v.map(verse => {
    return { i: verse.i, t: verse.t, x: verse.x, selected: selectedVerseNumbers.includes(verse.i) };
  }) || [];

  // Text of the selected verses
  // Include the verse numbers if more than one verse is selected
  // The Bible reference for the selected verses is like "– Gen 1:1-5"
  const selectedVerses = verses.filter(verse => selectedVerseNumbers.includes(verse.i));
  const selectedVersesRef = selectedVerses.length > 0 ? `${bookData?.a} ${chapterData?.i}:${selectedVerses[0].i}${selectedVerses.length > 1 ? `-${selectedVerses[selectedVerses.length - 1].i}` : ''} (${bible.versionAbbreviation})` : '';
  const selectedVersesText = selectedVerses.map(verse => `${selectedVerses.length > 1 ? `${verse.i}. ` : ''}${removeAllTags(verse.t)}`).concat(`– ${selectedVersesRef}`).join('\n');
  const shareLink = `${window.location}`;

  const audioEnabled = bible.audioEnabled;

  if (!chapterData) {
    return <NoPage />;
  }

  const prev = chapterNumber > 1 ? `/${translation}/${bookNumber}/${chapterNumber - 1}` : (bookNumber > 1 ? `/${translation}/${bookNumber - 1}` : undefined);
  const up = `/${translation}/${bookNumber}`;
  const next = bookData && chapter && chapterNumber < bookData.c.length ? `/${translation}/${bookNumber}/${chapterNumber + 1}` : (bookNumber < 66 ? `/${translation}/${bookNumber + 1}/1` : undefined);

  const onClickVerse = (event: React.MouseEvent<HTMLSpanElement>, verse: number) => {
    event.preventDefault();
    let selected = selectedVerseNumbers;
    if (selectedVerseNumbers.includes(verse)) {
      selected = selectedVerseNumbers.filter(v => v !== verse);
    } else {
      selected = [...selectedVerseNumbers, verse];
    }
    selected.sort((a, b) => a - b);
    setSelectedVerseNumbers(selected);
    navigate(`#${selected.join(",")}`);
  }

  const onCloseShareButtons = () => {
    setSelectedVerseNumbers([]);
    navigate('#');
  }

  const onAudioEnded = () => {
    if (next) {
      navigate(`${next}?autoplay=true`);
    }
  };

  const onAudioPlay = () => {
    setStickyPlayer(true);
  }

  const onAudioPause = () => {
    setStickyPlayer(false);
    if (autoPlay) {
      navigate(`/${translation}/${bookNumber}/${chapterNumber}`);
    }
  }

  const audioUrl = `/audio/${translation}/${String(bookNumber).padStart(2, '0')}_${String(chapterNumber).padStart(3, '0')}.mp3`;

  return (bookData && chapterData ?
    <section className="App-main-section">
      {isOnline && audioEnabled && <div className={`App-audio-player-container ${stickyPlayer && "App-sticky"}`}>
        <AudioPlayer src={audioUrl} autoPlay={autoPlay} onPlay={onAudioPlay} onPause={onAudioPause} onEnded={onAudioEnded} />
      </div>}
      <div className="App-content">
        <Navigation prev={prev} up={up} next={next} />
        <h2>{bookData.n} {chapterData.i}</h2>
        <p className="App-verses">
          {verses.map((verse) => (
            <span className="App-verse" key={`verse-${verse.i}`} id={`verse-${verse.i}`}>
              <span className="App-verse-number">{verse.i} </span>
              <span onClick={(event) => onClickVerse(event, verse.i)} className={`${verse.selected ? 'App-selected' : ''}`}><VerseText text={verse.t} /></span>
              {verse.x && verse.x.length > 0 &&
                <span className="App-cross-references"> {verse.x.map(x =>
                  <Link key={`x-${x[0]}-${x[1]}-${x[2]}`} to={`/${translation}/${x[0]}/${x[1]}#${x[2]}`}>
                    {`${getBookAbbreviation(bible, x[0])} ${x[1]}:${x[2]}`}
                  </Link>
                )}</span>
              }
            </span>
          ))}
        </p>
        <Navigation prev={prev} up={up} next={next} />
      </div>
      {selectedVerseNumbers.length > 0 &&
        <ShareButtons
          selectedVersesRef={selectedVersesRef}
          selectedVersesText={selectedVersesText}
          shareLink={shareLink}
          onClose={onCloseShareButtons} />
      }
    </section>
    : <></>
  );
};

function getBookAbbreviation(bible: Bible, bookNumber: number) {
  return bible.parts.flatMap(part => part.books)
    .find(book => book.i === bookNumber)?.a;
}
