import React from 'react';
import { Bible } from '../types/bibleTypes';

export interface BibleParams {
  translation: string;
  bible: Bible;
}

const initialValue: BibleParams = {
  translation: '',
  bible: {
    lang: '',
    title: '',
    versionName: '',
    versionAbbreviation: '',
    internationalVersionAbbreviation: '',
    description: '',
    license: '',
    crossRefAttribution: '',
    audioAttribution: '',
    audioEnabled: false,
    publisher: '',
    publisherUrl: '',
    publishDate: '',
    parts: [],
    index: {}
  }
};

const BibleContext = React.createContext<BibleParams>(initialValue);

export default BibleContext;
