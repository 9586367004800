import { useContext } from "react";
import { Link } from "react-router-dom";
import BibleContext from "../contexts/bibleContext";
import VerseText from "../components/verseText";

export default function About() {
  const { bible } = useContext(BibleContext);

  const book = bible && bible.parts[1].books[15];
  const chapter = book && book.c[2];
  const verse1 = chapter && chapter.v[15];
  const verse2 = chapter && chapter.v[16];
  const versionName = bible?.versionName;
  const versionAbbreviation = bible?.versionAbbreviation;
  const audioEnabled = bible?.audioEnabled;
  const locale = bible?.lang;
  const publisher = bible?.publisher;
  const publisherUrl = bible?.publisherUrl;
  const license = bible?.license;
  const formattedDate = bible && bible.publishDate && new Date(bible.publishDate).toLocaleDateString(locale);

  // Replace links with actual links
  const description = prepareHtml(bible?.description);
  const crossRefAttribution = prepareHtml(bible?.crossRefAttribution);
  const audioAttribution = prepareHtml(bible?.audioAttribution);

  return (bible &&
    <section>
      <div className="App-content">
        <h2>{versionName} ({versionAbbreviation})</h2>
        <p>{formattedDate}{publisher && publisherUrl && <>, <Link to={publisherUrl} target="_blank">{publisher}</Link></>}, {license}</p>
        <p dangerouslySetInnerHTML={{ __html: description }}></p>
        <p dangerouslySetInnerHTML={{ __html: crossRefAttribution }}></p>
        {audioEnabled && <p dangerouslySetInnerHTML={{ __html: audioAttribution }}></p>}
        {book && chapter && verse1 && verse2 && <p><VerseText text={verse1.t} /> <VerseText text={verse2.t} /><br /><br /><i>–&nbsp;{book.s}&nbsp;{chapter.i}:{verse1.i}-{verse2.i}</i></p>}
      </div>
    </section>
  );
}

function prepareHtml(text: string | undefined) {
  return text?.replaceAll('\n', '<br />').replace(/(https?:\/\/[^\s)\]]+)/g, '<a href="$1" target="_blank">$1</a>') || "";
}