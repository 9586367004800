import Navigation from '../components/navigation';
import { Link, useParams } from 'react-router-dom';
import NoPage from './noPage';
import { useContext } from 'react';
import BibleContext from '../contexts/bibleContext';

export default function Book() {
  const { translation, bible } = useContext(BibleContext);
  const { book } = useParams<{ book: string }>();

  const bookNumber = parseInt(book || '1');
  const books = bible.parts.flatMap(part => part.books);
  const bookData = books.find(book => book.i === bookNumber);

  if (!bookData) {
    return <NoPage />;
  }

  const prev = bookNumber > 1 ? `/${translation}/${bookNumber - 1}` : undefined;
  const up = `/${translation}/`;
  const next = book && bookNumber < books.length ? `/${translation}/${bookNumber + 1}` : undefined;

  return (bookData ?
    <section>
      <div className="App-content">
        <Navigation prev={prev} up={up} next={next} />
        <h2>{bookData.n}</h2>
        <div className="App-chapters">
          {bookData.c.map((chapter, index) => (
            <Link to={`${chapter.i}`} key={index}>{chapter.i}</Link>
          ))}
        </div>
      </div>
    </section>
    : <></>
  );
};
