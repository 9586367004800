import { Link } from "react-router-dom";

interface Props {
  prev?: string;
  up?: string;
  next?: string;
}

export default function Navigation({ prev, up, next }: Props) {
  return (
    <div className="App-versenav">
      {prev && <Link className="" to={prev}><i className='bx bxs-chevron-left'></i></Link>}
      {!prev && <span className=""><i className='bx bxs-chevron-left'></i></span>}
      {up && <Link className="" to={up}><i className='bx bxs-chevron-up'></i></Link>}
      {!up && <span className=""><i className='bx bxs-chevron-up'></i></span>}
      {next && <Link className="" to={next}><i className='bx bxs-chevron-right'></i></Link>}
      {!next && <span className=""><i className='bx bxs-chevron-right'></i></span>}
    </div>
  )
}
