import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { setTranslation, translations } from '../modules/translations';

interface Props {
  translation: string;
  title: string;
}

const Header: React.FC<Props> = ({ translation, title }) => {
  const [translationSelectorVisible, setTranslationSelectorVisible] = React.useState(false);

  const toggleTranslationSelector = () => {
    setTranslationSelectorVisible(!translationSelectorVisible);
  };

  const onClickTranslation = (key: keyof typeof translations) => {
    setTranslation(key);
    setTranslationSelectorVisible(false);
  }

  const { book, chapter } = useParams<{ book: string, chapter: string }>();

  // Create reference if book and chapter are available or if only book is available or if none are available
  const reference = book && chapter ? `${book}/${chapter}` : book ? book : '';

  return (
    <header className="App-header" id="header">
      <nav>
        <a href={`/${translation}/`} className="App-home-link"><img src="/cross.svg" alt="Cross" className="App-logo" /></a>
        <span onClick={toggleTranslationSelector} className="App-title">{title} <i className="bx bx-caret-down"></i></span>
        <Link to={`/${translation}/search`} className="App-nav-link"><i className="bx bx-search"></i></Link>
      </nav>
      {translationSelectorVisible && <nav className="App-translations">
        <ul>
          {
            Object.keys(translations).map((key) => (
              <li key={key}>
                <Link to={`/${key as keyof typeof translations}/${reference}`} onClick={() => onClickTranslation(key as keyof typeof translations)}>
                  {translations[key as keyof typeof translations]}
                </Link>
              </li>
            ))
          }
        </ul>
      </nav>
      }
    </header>
  );
};

export default Header;
