import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SearchResult } from "../types/searchTypes";
import Loader from "../components/loader";
import BibleContext from "../contexts/bibleContext";
import stemWord from "../modules/stemmer";
import processBibleTags from "../modules/bible-tag-processor";

const matchWordRegExpIgnoreCase = (word: string) => {
  try {
    return new RegExp(`(?<![a-zA-ZåäöÅÄÖ-])${word.trim()}`, 'gi');
  } catch (e) {
    return new RegExp(`${word.trim()}`, 'gi'); // Fallback to not using negative lookbehind
  }
};
const maxResults = 2000;

export default function Search() {
  const { terms } = useParams<{ terms: string }>();
  const [searchTerm, setSearchTerm] = useState<string>(terms || '');
  const [results, setResults] = useState<SearchResult[]>([]);
  const navigate = useNavigate();
  const { translation, bible } = useContext(BibleContext);
  const searchTerms = searchTerm.trim().toLowerCase().replace(/[!"#€%&/)(=?*,.;:><\][+´`@]/g, "").split(/[ \t]+/);
  const lang = bible?.lang || '';

  function search() {
    if (!bible) return;

    navigate(`/${translation}/search/${encodeURIComponent(searchTerm)}`);

    const firstLetter = searchTerms[0][0];

    const stemmedSearchTerms = searchTerms.map(term => stemWord(term, lang));

    // TODO use searchTermRegex to match words in index

    // Search words in index matching the search term optimize search using firstLetter
    const searchResults = bible.index[firstLetter]?.[stemmedSearchTerms[0]]?.map(([book, chapter, verse]) => {
      const bookData = bible.parts.flatMap(part => part.books).find(bookData => bookData.i === book);
      const chapterData = bookData?.c.find(chapterData => chapterData.i === chapter);
      const verseData = chapterData?.v.find(verseData => verseData.i === verse);
      return verseData ? { bookAbbreviation: bookData?.a, book: book, chapter: chapter, verse: verse, text: verseData.t } : undefined;
    }) || [];

    const nonEmptyResults = searchResults.filter(result => result !== undefined) as SearchResult[];
    const uniqueResults = nonEmptyResults.filter((result, index, self) => self.findIndex(r => r.book === result.book && r.chapter === result.chapter && r.verse === result.verse) === index);
    const resultsContainingAllTerms = uniqueResults.filter(result => stemmedSearchTerms.every(term => matchWordRegExpIgnoreCase(term).test(result.text)));
    setResults(resultsContainingAllTerms.slice(0, maxResults + 1).sort((a, b) => a.book - b.book || a.chapter - b.chapter || a.verse - b.verse));
  }

  useEffect(() => {
    const cleanSearchTerm = searchTerm?.replace(/[.…,:;!?"&%#*+=/)(]/g, "");
    if (cleanSearchTerm === searchTerm) {
      const getData = setTimeout(() => {
        if (cleanSearchTerm && cleanSearchTerm.length > 1) {
          search();
        }
      }, 500)
      return () => clearTimeout(getData)
    } else {
      setSearchTerm(cleanSearchTerm);
    }
  }, [bible, searchTerm]);

  useEffect(() => {
    if (terms?.trim() === searchTerm?.trim()) {
      return;
    }
    setSearchTerm(terms || '');
  }, [bible, terms]);

  if (!bible) {
    return <Loader />;
  }

  const stemmedSearchTerms = searchTerms.map(term => stemWord(term, lang).replaceAll('[*?]', ''));
  const termsRegex = (searchTerms && searchTerms[0].length > 1)
    ? matchWordRegExpIgnoreCase(`(${stemmedSearchTerms.join("|")})[^ ,.:;—!?'"]*`)
    : "";
  const placeholder = bible.parts[0].books[22].c[54].v[5].t;

  return (
    <section>
      <div className="App-content">

        <div className="App-search-form">
          <input
            className="App-search-input"
            type="text"
            value={searchTerm}
            placeholder={placeholder}
            onChange={e => setSearchTerm(e.target.value)}
            autoFocus
          />
        </div>
        {searchTerm && results && <div className="App-result-amount"><i className="bx bx-search"></i> {results.length > maxResults ? `${maxResults}+` : results.length}</div>}
        {Array.isArray(results) && results.map(result => {
          let highlightedText = processBibleTags(result.text);
          highlightedText = termsRegex ? highlightedText.replaceAll(termsRegex, '<mark class="App-highlight">$&</mark>') : highlightedText;
          return (
            <div key={`${result.book}-${result.chapter}-${result.verse}`} className="mb-4">
              <p>
                <Link to={`/${translation}/${result.book}/${result.chapter}#${result.verse}`}>{result.bookAbbreviation} {result.chapter}:{result.verse}</Link>
                <span> </span>
                <span dangerouslySetInnerHTML={{ __html: highlightedText }}></span>
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
}
