import { useContext } from 'react';
import { Link } from 'react-router-dom';
import BibleContext from '../contexts/bibleContext';

export default function Books() {
  const { translation, bible } = useContext(BibleContext);

  return (
    <section>
      <div className="App-content">
        {bible.parts.map((part, index) => (
          <section key={index}>
            <h2 key={index}>{part.title}</h2>
            <div className="App-books">
              {part.books.map((book, index) => (
                <Link to={`/${translation}/${book.i}`} key={index}>{book.s}</Link>
              ))}
            </div>
          </section>
        ))}
      </div>
    </section>
  );
};
