const fi_stem_percentage = 33;

export default function stemWord(word: string, lang: string) {
  if (lang === 'fi' && word.length > 3) {
    return word.slice(0, Math.max(word.length - Math.round(word.length * fi_stem_percentage / 100), word.length - 3));
  } else if (lang === 'en' && word.length > 3) {
    // Make possessive words regular
    if (word.endsWith('\'s')) {
      return word.slice(0, word.length - 2);
    }
    // Make plural words singular
    else if (word.endsWith('s')) {
      return word.slice(0, word.length - 1);
    }
    // Make past tense words present
    else if (word.endsWith('ed')) {
      return word.slice(0, word.length - 2);
    }
    // Make continuous tense words present
    else if (word.endsWith('ing')) {
      return word.slice(0, word.length - 3);
    }
    return word;
  }
  return word;
}
