import { Link } from "react-router-dom";

export default function NoPage() {
  return (
    <section>
      <div className="App-content">
        <h2>404 <i className="bx bx-meh"></i></h2>
        <p><Link to="/"><i className="bx bx-home App-large-icon"></i></Link></p>
      </div>
    </section>
  );
}
