import { Outlet, useNavigate, useParams } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import { Bible } from "./types/bibleTypes";
import { useEffect, useState } from "react";
import Loader from "./components/loader";
import BibleContext from "./contexts/bibleContext";

export default function BibleLayout() {
  const { translation } = useParams<{ translation: string }>();
  const sanitizedTranslation = translation?.replace(/[^a-z]/g, '');
  const [bible, setBible] = useState<Bible | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sanitizedTranslation) {
      return;
    }
    fetch(`/bible/${sanitizedTranslation}.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data: Bible) => {
        setBible(data);
      })
      .catch(error => {
        console.error('Failed to load the Bible:', error);
        navigate('/');
      });
  }, [sanitizedTranslation]);

  if (!sanitizedTranslation) {
    navigate('/');
    return null;
  }
  if (!bible || bible.parts.length === 0) {
    return <Loader />;
  }

  return (
    <BibleContext.Provider value={{ translation: sanitizedTranslation, bible: bible }}>
      <Header translation={sanitizedTranslation} title={bible.title} />
      <main className="App-main">
        <Outlet />
      </main>
      <Footer translation={sanitizedTranslation} versionName={bible.versionName} versionAbbreviation={bible.versionAbbreviation} publishDate={bible.publishDate} license={bible.license} locale={bible.lang} />
    </BibleContext.Provider>
  );
}
