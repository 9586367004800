import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setTranslation, translations } from "../modules/translations";

export default function Home() {
  const navigate = useNavigate();

  // Check if translation is set in local storage
  const translation = localStorage.getItem("translation");

  useEffect(() => {
    if (translation && translations[translation as keyof typeof translations]) {
      navigate(`/${translation}/`);
      return;
    }
  });

  return (
    <section>
      <header className="App-header" id="header">
        <nav>
          <img src="/cross.svg" alt="Cross" className="App-logo" />
          <span className="App-title">OpenVersion.net</span>
          <span> </span>
        </nav>
      </header>
      <div className="App-content">
        <div className="App-home">
          <h1>Choose Your Bible Translation</h1>
          <ul>
            {
              Object.keys(translations).map((key) => (
                <li key={key}>
                  <Link to={`/${key as keyof typeof translations}/`} onClick={() => setTranslation(key as keyof typeof translations)}>
                    {translations[key as keyof typeof translations]}
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </section>
  );
}
